<template>
  <section class="pr-4">
    <div class="m-1 mr-4 px-0">
      <breadcrumb title="BI" actualPage="Integrado" />
    </div>
    <div>
      <div id="wait_loading_embbeded" v-if="this.loading" class="pt-10">
        <h4>Aguarde, vamos carregar o BI...</h4>
      </div>
      <div id="embedContainer" style="width:100%;height:1620px"></div>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import * as pbi from "powerbi-client";
import axiosInstance from "@/axios";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";

export default {
  components: { Breadcrumb },
  directives: {},
  data() {
    return {
      listaCorretores: [],
      totalItems: 0,
      currentPage: 1,
      rowsPerPage: 7,
      numberOfPages: 0,
      searchValue: "",
      loading: false
    };
  },
  watch: {
    currentPage(newVal, oldVal) {
      this.listarCorretores();
    }
  },
  mounted() {
    this.carregarBI();
  },
  computed: {},
  methods: {
    carregarBI() {
      this.$onpoint.loading(() => {
        return axiosInstance
          .get(`api/Core/GetEmbbeded`)
          .then(response => {
            this.montarBI(
              response.data.Response.AccessToken,
              response.data.Response.EmbbedURL,
              response.data.Response.ReportId
            );
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },
    montarBI(txtAccessToken, txtEmbedUrl, txtEmbedReportId) {
      this.loading = true;
      let decodeUrl = window.atob(txtEmbedUrl);
      // Embed configuration used to describe the what and how to embed.
      // This object is used when calling powerbi.embed.
      // This also includes settings and options such as filters.
      // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details.
      var config = {
        type: "report",
        tokenType: 1,
        accessToken: txtAccessToken,
        embedUrl: decodeUrl,
        id: txtEmbedReportId,
        permissions: 0,
        settings: {
          panes: {
            filters: {
              visible: false
            },
            pageNavigation: {
              visible: true
            }
          }
        }
      };
      // Get a reference to the embedded report HTML element
      var embedContainer = document.getElementById("embedContainer");
      // Embed the report and display it within the div container.
      var report = powerbi.embed(embedContainer, config);
      // Report.off removes a given event handler if it exists.
      report.off("loaded");
      // Report.on will add an event handler which prints to Log window.
      report.on("loaded", function() {
        Log.logText("Loaded");
      });
      // Report.off removes a given event handler if it exists.
      report.off("rendered");
      // Report.on will add an event handler which prints to Log window.
      report.on("rendered", function() {
        Log.logText("Rendered");
      });
      report.on("error", function(event) {
        Log.log(event.detail);
        report.off("error");
      });
      report.off("saved");
      report.on("saved", function(event) {
        Log.log(event.detail);
        if (event.detail.saveAs) {
          Log.logText(
            "In order to interact with the new report, create a new token and load the new report"
          );
        }
      });
      this.loading = false;
    }
  },
  created() {
    this.$appInsights.trackPageView({
      name: "bi_embbeded",
      url: window.location.href
    });
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins";

.table-list {
  margin-top: -32px;

  ::v-deep .vs-table--thead {
    tr {
      background: transparent !important;
    }
  }

  ::v-deep .vs-table--td {
    padding: 25px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .vs-table--search {
    max-width: initial;
    margin-bottom: 20px;
    i {
      font-size: 2em;
      margin-top: 30px;

      @include md {
        margin-top: -24px;
      }
    }

    input {
      padding-left: 40px !important;
      font-size: 1.1em !important;
      width: 300px;
      border: 0 !important;
      height: 47px;
      margin-top: 30px;

      @include md {
        margin-top: -24px;
      }
    }
  }

  ::v-deep .vs-table-text {
    justify-content: center;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}
</style>
